<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">상품군</v-col>
          <v-col cols="8">
            <v-select
              @click="getPrProdLineCd"
              v-model="prProdLineCd"
              :items="prProdLineCdList"
              item-text="prProdLineNm"
              item-value="prProdLineCd"
              background-color="white"
              class="subBar"
              hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">보험사</v-col>
          <v-col cols="7">
            <v-select
                @click="getInsCdList"
                v-model="insCd"
                :items="insCdList"
                background-color="white"
                item-text="insCdNm"
                item-value="insCd"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">신정원 담보코드</v-col>
          <v-col cols="7">
            <v-text-field
                v-model="creCvrCd"
                background-color="white"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">상품코드</v-col>
          <v-col cols="8">
            <v-text-field
                v-model="cvrCd"
                background-color="white"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">공통여부</v-col>
          <v-col cols="5">
            <v-select
                v-model="commCmpCvrYn"
                :items="commCmpCvrYnList"
                background-color="white"
                item-text="commCmpCvrYnNm"
                item-value="commCmpCvrYn"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">변경여부</v-col>
          <v-col cols="5">
            <v-select
                v-model="chngYn"
                :items="chngYnList"
                background-color="white"
                item-text="chngYnNm"
                item-value="chngYn"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">성별</v-col>
          <v-col cols="8">
            <v-radio-group v-model="sex" row>
              <v-radio label="남자" value="1"/>
              <v-radio label="여자" value="2"/>
            </v-radio-group>
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">연령</v-col>
          <v-col cols="5">
            <v-text-field
                v-model="age"
                background-color="white"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">사용여부</v-col>
          <v-col cols="5">
            <v-select
                v-model="useYn"
                :items="useYnList"
                item-text="useYnNm"
                item-value="useYn"
                background-color="white"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="6">보험료 매핑여부</v-col>
          <v-col cols="5">
            <v-select
                v-model="mappYn"
                :items="mappYnList"
                item-text="mappYnNm"
                item-value="mappYn"
                background-color="white"
                class="subBar"
                hide-details outlined dense
            />
          </v-col>
        </v-col>

        <v-col class=" col-xl-6 col-lg-6 col-md-6 col-12">
          <div class="float-right">
            <v-btn
                :disabled="data.length <= 0"
                class="saveBtn grey lighten-1"
                @click="saveData"
            >저장</v-btn>
            <v-btn
                :disabled="insCd === ''"
                class="totalBtn yellow darken-1"
                @click="monthlyPremTotal"
            >보험료집계</v-btn>
            <v-btn
                class="searchBtn grey lighten-1"
                @click="getSearch"
            >조회</v-btn>
          </div>
        </v-col>
      </search-area>
    </template>

  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import {axiosInstance} from "@/util/AxiosModule";
import ProductRecommend from "@/util/ProductRecommend";
import _ from "lodash";

export default {
  props: ["data", "selectData"],
  components: {SearchArea, SearchBarLayout},
  data() {
    return {
      prProdLineCd: "",
      prProdLineCdList: [
        {prProdLineNm: "선택", prProdLineCd: ""}
      ],
      sex: "1",
      age: "40",
      useYn: "Y",
      useYnList: [
        {useYnNm: "Y", useYn: "Y"},
        {useYnNm: "N", useYn: "N"},
      ],
      mappYn: "Y",
      mappYnList: [
        {mappYnNm: "Y", mappYn: "Y"},
        {mappYnNm: "N", mappYn: "N"},
      ],
      commCmpCvrYn: "",
      commCmpCvrYnList: [
        {commCmpCvrYnNm: "전체", commCmpCvrYn: ""},
        {commCmpCvrYnNm: "Y", commCmpCvrYn: "Y"},
        {commCmpCvrYnNm: "N", commCmpCvrYn: "N"},
      ],
      insCd: "",
      insCdList: [
        {insCdNm: "전체", insCd: ""}
      ],
      chngYn: "",
      chngYnList: [
        {chngYnNm: "전체", chngYn: ""},
        {chngYnNm: "Y", chngYn: "Y"},
        {chngYnNm: "N", chngYn: "N"},
      ],
      creCvrCd: "",
      cvrCd: "",
      prCd: "",
    }
  },
  methods: {
    async getPrProdLineCd() {
      this.prProdLineCdList = await ProductRecommend.getPrProdLineCd();
      this.prProdLineCdList.shift();
    },
    async getInsCdList() {
      this.insCdList = await ProductRecommend.getInsCd();
    },
    async saveData() {
      let prCreCvrCdPremMappingList = this.selectData;

      if (confirm(this.selectData.length + "개의 데이터를 저장하시겠습니까?")) {
        try{
          await axiosInstance.post('/admin/prdata/prCreCvrCdPremMappSave', prCreCvrCdPremMappingList);
          alert(this.selectData.length + "개의 데이터가 저장완료되었습니다.");
        } catch (e) {
          console.log(e);
        }
      }
      this.$emit("updateData");
    },
    getSearch() {
      const params = {
        prProdLineCd: this.prProdLineCd,
        insCd: this.insCd,
        creCvrCd: this.creCvrCd,
        cvrCd: this.cvrCd,
        commCmpCvrYn: this.commCmpCvrYn,
        sex: this.sex,
        age: this.age,
        useYn: this.useYn,
        chngYn:this.chngYn,
        mappYn: this.mappYn,
      }
      this.$emit("getSearch", params);
    },
    async monthlyPremTotal() {
      let insCd = this.insCd;
      let prProdLineCd = this.prProdLineCd;
      let prCd = this.prCd;

      if (_.isEmpty(insCd)) {
        alert('보험사 누락');
        return false;
      }
      if (_.isEmpty(prProdLineCd)) {
        alert('상품군 누락');
        return false;
      }
      if (_.isEmpty(prCd)) {
        alert('상품코드 누락');
        return false;
      }

      let url = `/admin/prdata/prPremAggSave?insCd=${insCd}&prProdLineCd=${prProdLineCd}&prCd=${prCd}`;
      try {
        const { data } = await axiosInstance.put(url);

        if (data === null) {
          alert("0개의 데이터가 집계되었습니다.");
        } else {
          alert(data + "개의 데이터가 집계되었습니다.");
        }
      }catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>
.saveBtn {
  margin-right: 5px;
}
.searchBtn {
  margin-left: 5px;
}
</style>