<template>
  <v-overlay
      v-model="isShow"
      :light="true"
      :dark="false">
      <v-card max-width="80vw" max-height="100vh" class="overflow-y-auto">
        <v-card-title class="headline">
          <v-col cols="12">
            <span class="font-weight-bold">업로드 엑셀목록</span>
          </v-col>
        </v-card-title>
        <div class="my-2">
          <span class="text-h6">목록 (총 {{ this.uploadData.length }}개)</span>
          <v-btn
              class="table_sty green lighten-2 white--text float-right"
              @click="clickUpload"
          >엑셀업로드</v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <a
                  class="float-right"
                  href="/template/disease_prediction_sample.xlsx"
                  download="질병예측_샘플.xlsx"
                  v-on="on"
              >
                <v-icon>mdi-file-excel-outline</v-icon>
              </a>
            </template>
            <span>양식 다운로드</span>
          </v-tooltip>
          <input @input="loadData" :accept="SheetJSFT" class="d-none" id="excel" type="file" ref="file"/>
        </div>
        <v-data-table
            height="50vh"
            v-model="selectData"
            item-key="index"
            show-select
            :items="uploadData"
            :headers="getHeader(headers)"
            :item-class="getRed"
            fixed-header calculate-widths
            :footer-props="{'items-per-page-options': [50, -1]}"
        >

          <template v-slot:item.unitPrice="{ value }">
            {{ inputComma(value) }}
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="yellow darken-2"
              class="white--text col-2"
              @click="sendAlarmTalk">
            알림톡 발송
          </v-btn>
          <v-btn
              color="red darken-4"
              class="white--text col-2"
              @click="close">
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-overlay>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import XLSX from "xlsx";
import {axiosInstance} from "@/util/AxiosModule";
import store from "@/store";
import _ from "lodash";
import {EVENT_LOTTERY_EXCEL_UPLOAD_MODAL_TABLE} from "@/util/TableHeaders";

export default {
  mixins: [TableMixin],
  props: ["isShow"],
  data() {
    return {
      text: "샘플 다운로드",
      option: {
        sortBy: ['isRed']
      },
      SheetJSFT: ["xlsx", "xlsb", "xlsm", "xls", "xml", "csv"].map(x => "." + x).join(","),
      headers: EVENT_LOTTERY_EXCEL_UPLOAD_MODAL_TABLE,
      uploadData: [],
      selectData: [],
    }
  },
  methods: {
    close() {
      this.selectData = [];
      this.$emit('close');
    },
    // Excel파일 업로드
    async upload(memberList) {

      try {
        const {data} = await axiosInstance.post(
            `/admin/operation/eventAwarding`, memberList, {
              params: {
                regId: store.getters['login/memberId']
              }
            }
        );
        let idx = 0;
        for (const item of data) { item["index"] = idx++; }
        let checkData = [];
        for (let i = 0; i < data.length; i++) {
          checkData = data.map(x => ({
            ...x,
            isSelectable: x.regYn === "Y"
          }));
        }
        this.uploadData = checkData;
        alert("엑셀파일 업로드 완료");
        this.$emit('updateData');
      } catch (error) {
        const { response: { status } } = error;
        if(status === 500) {
          alert("이미 입력된 데이터 항목이 있습니다");
        }
      }
    },
    loadData( { target: { files }}) {
      // 바이너리(2진법)로 읽음
      this._setReader().readAsBinaryString(files[0]);
    },
    // 엑셀파일 읽어오기
    _setReader() {
      // FileReader를 생성
      const reader = new FileReader();
      // 성공적으로 읽기 동작이 완료된 경우 실행되는 이벤트
      reader.onload = ({ target: { result }}) => {
        // Excel파일을 읽어서 처리하는 로직
        const { SheetNames, Sheets } = XLSX.read(result, { type: "binary" });

        Sheets[SheetNames].A1.w = "eventType"
        Sheets[SheetNames].B1.w = "yyyymm"
        Sheets[SheetNames].C1.w = "eventNum"
        Sheets[SheetNames].D1.w = "memberNm"
        Sheets[SheetNames].E1.w = "telNum"
        Sheets[SheetNames].F1.w = "awardingType"
        Sheets[SheetNames].G1.w = "cnt"
        Sheets[SheetNames].H1.w = "unitPrice"

        const memberList = XLSX.utils.sheet_to_json(Sheets[SheetNames]);
        this.upload(memberList);
      }
      return reader;
    },
    // 엑셀업로드 클릭 이벤트
    clickUpload() {
      this.$refs.file.click();
    },
    getRed(item) {
      return item.regYn === 'N' ? 'isRed' : '';
    },
    async sendAlarmTalk() {
      if (_.isEmpty(this.selectData)) return;
      let memberList = this.selectData;

      if (confirm("알림톡을 발송을 하겠습니까?")) {
        try {
          await axiosInstance.post(`/admin/operation/eventAwardingTalk`, memberList, {
            params: {
              regId: store.getters['login/memberId']
            }
          })
          alert("발송되었습니다.");
          this.filterDataList();
          this.selectData = [];
        } catch (e) {
          console.log(e);
        }
      }
    },
    filterDataList() {
      this.uploadData = this.uploadData.filter(uploadItem => {
        return !this.selectData.some(selectItem => this.isEqual(uploadItem, selectItem));
      })
    },
    isEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    }
  }
}
</script>

<style scoped>
.my-2 span {
  margin-left: 10px;
}
.my-2 a {
  text-decoration: none;
  margin: 5px 15px;
}
.my-2 button {
  margin-right: 10px;
}
</style>