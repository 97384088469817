<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-row>
          <v-col class="d-flex justify-center align-center col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <v-col cols="5">회원가입연도</v-col>
            <v-select
                v-model="baseYear"
                :items="baseYearList"
                item-text="baseYearNm"
                item-value="baseYear"
                outlined dense hide-details
                background-color="white"
            />
          </v-col>
          <v-col class="d-flex justify-center align-center col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <v-col cols="5">기준연월</v-col>
            <v-text-field
              v-model="searchBaseYM"
              outlined dense hide-details
              background-color="white"
            />
          </v-col>
        </v-row>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout.vue";
import SearchArea from "@/components/Search/SearchArea.vue";
import SearchBtn from "@/components/Search/SearchBtn.vue";

export default {
  components: {SearchBtn, SearchArea, SearchBarLayout},
  data() {
    return {
      searchBaseYM: "",
      baseYear: "",
      baseYearList: []
    }
  },
  computed: {
    nowDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      return `${year}` + `${month < 10 ? 0 + "" + month : month}`;
    }
  },
  mounted() {
    this.searchBaseYM = this.nowDate;
    // 등록연도 리스트 생성
    const currentYear = new Date().getFullYear();
    this.baseYear = currentYear;
    this.baseYearList = [];
    for (let i = -5; i <= 0; i++) {
      const year = currentYear + i;
      this.baseYearList.push({
        baseYearNm: year.toString(),
        baseYear: year,
      });
    }
  },
  methods: {
    getSearch() {
      const params = {
        searchBaseYM: this.searchBaseYM,
        baseYear: this.baseYear.toString()
      };
      this.$emit("search", params);
    }
  }
}
</script>