<template>
  <v-col cols="12" class="py-0">
    <span class="text-h6">목록 (총 {{ data.length }}개)</span>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header disable-sort calculate-widths
          :headers="getHeader(USER_SEARCH_HEADER)"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.email="{ value }">
          <span class="normal">{{ value }}</span>
        </template>

        <template v-slot:item.birthday="{ value }">
          {{ getDotsBirthday(value) }}
        </template>

        <template v-slot:item.inflowList="{ item }">
          <v-icon @click="searchInflow(item)">pageview</v-icon>
        </template>

        <template v-slot:item.inforList="{ item }">
          <v-icon @click="searchInfor(item)">pageview</v-icon>
        </template>

        <template v-slot:item.iimsDetail="{ item }">
          <v-btn color="red lighten-5" class="px-1 salseCard" @click="getAPI(item, 'compareHistory')">상품추천</v-btn>
          <v-btn color="yellow lighten-5" class="px-1 salseCard" @click="getAPI(item, 'salesCard')">세일즈 카드</v-btn>
          <v-btn color="blue lighten-5" class="px-1 salseCard" @click="getAPI(item, 'contractPremAmt')">보험료 비교</v-btn>
        </template>

        <template v-slot:item.telecomCompany="{ value }">
          {{ value ? searchByCode(TELECOM_COMPANY, value) : '' }}
        </template>

        <template v-slot:item.inviterNm="{ value }">
          {{ value ? value : '없음' }}
        </template>

        <template v-slot:item.telNum="{ value }">
          {{ value ? `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}` : '' }}
        </template>

        <template v-slot:item.createDate="{ value }">
          <span v-if="value">{{ new Date(value).toLocaleString() }}</span>
        </template>

        <template v-slot:item.modify="{ item }">
          <v-btn color="green darken-1" @click="modify(item)">변경</v-btn>
        </template>

        <template v-slot:item.signupTalk="{ item }">
          <v-btn color="yellow lighten-5" class="px-1 salseCard" @click="sendSignUpTalk(item, 'signup')">회원가입</v-btn>
          <v-btn color="blue lighten-5" class="px-1 salseCard" @click="sendSignUpTalk(item, 'update')">회원정보 변경</v-btn>
        </template>

        <template v-slot:item.resetPassword="{ item }">
          <v-btn color="red lighten-1" class="px-1 salseCard" @click="resetPW(item)">초기화</v-btn>
        </template>
      </v-data-table>

      <user-modify-popup
          :isShow="isModify"
          :modifyData="modifyData"
          :isPrvGroupTM="isPrvGroupTM"
          @close="closeModify"
      />
    </v-flex>
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import CodeMixin from "@/mixin/CodeMixin";
import {USER_SEARCH_HEADER} from "@/util/TableHeaders";
import {axiosInstance} from "@/util/AxiosModule";
import UserModifyPopup from "@/pages/User/components/UserSearch/Popup/UserModifyPopup.vue";

export default {
  components: {UserModifyPopup},
  mixins: [TableMixin, CodeMixin],
  props: ['data', 'loading', 'isPrvGroupTM'],
  async created() {
    this.TELECOM_COMPANY = await this.getDetailCodes('TELECOM_COMPANY');
  },
  data: () => ({
    USER_SEARCH_HEADER : USER_SEARCH_HEADER,
    TELECOM_COMPANY: [],
    apiData:[],
    isModify: false,
    modifyData: {}
  }),
  methods: {
    searchInflow(item) {
      this.$router.push({
        name: 'InflowHistSearch', params: {name: item.memberNm}
      })
    },
    searchInfor(item) {
      this.$router.push({
        name: 'CalcHistSearch', params: {name: item.memberNm}
      })
    },
    async getAPI(item, val) {
      const membershipDto = {
        birthday : item.birthday,
        orgNm : item.memberNm,
        telNum : item.telNum
      }
      try {
        const {data} = await axiosInstance.post('public/incar/membership', membershipDto);
        console.log(data.icToken)
        this.apiData = data;
      } catch (e) {
        console.log(e);
      }
      if (this.apiData.memberShip === "1" || this.apiData.memberShip === "2"){
        if (val === "salesCard") {
          if (process.env.NODE_ENV === "development") {
            const url = "https://test.1click.direct/incar/salescardnews/" + this.apiData.icToken;
            window.open(url, "_blank");
          } else {
            const url = "https://1click.direct/incar/salescardnews/" + this.apiData.icToken;
            window.open(url, "_blank");
          }
        } else if (val === 'contractPremAmt') {
          if (process.env.NODE_ENV === "development") {
            const url = "https://test.1click.direct/incar/carprem/" + this.apiData.icToken;
            window.open(url, "_blank");
          } else {
            const url = "https://1click.direct/incar/carprem/" + this.apiData.icToken;
            window.open(url, "_blank");
          }
        } else if (val === 'compareHistory') {
          if (process.env.NODE_ENV === "development") {
            const url = "https://test.1click.direct/incar/comphist/" + this.apiData.icToken;
            window.open(url, "_blank");
          } else {
            const url = "https://1click.direct/incar/comphist/" + this.apiData.icToken;
            window.open(url, "_blank");
          }
        }
      } else {
        if (this.apiData.memberShip === "3") {
          alert("비회원 입니다.");
        } else {
          alert("입력값 오류입니다.");
        }
      }
    },

    modify(item) {
      this.modifyData = item;
      this.isModify = true;
    },

    async sendSignUpTalk(item, type) {
      const typeStr = type == 'signup' ? '회원가입' : '회원정보 변경';

      if (confirm(item.memberNm + '님 에게 [' + typeStr + ']안내 알림톡을 발송 하시겠습니까?')) {
        try {
          await axiosInstance.get("/admin/send-signup-talk",{
            params: {
              memberId: item.memberId,
              type: type
            }
          });
          alert(typeStr + ' 알림톡 발송완료');
        }
        catch (e) {
          console.log(e);
        }
      }
    },

    async resetPW(item) {
      let memberCertDto = {
        memberId: item.memberId,
      }
      if (confirm(item.memberNm + "님의 비밀번호를 초기화 하시겠습니까?")) {
        try {
          await axiosInstance.post("/admin/password-init", memberCertDto);
          alert(item.memberNm + "님의 비밀번호 초기화 완료!");
        } catch (e) {
          console.log(e);
        }
      }
    },
    closeModify() {
      this.modifyData = {};
      this.isModify = false;
      this.$emit("update");
    }
  }
}
</script>

<style scoped>
.salseCard {
  margin-right: 4px;
  margin-left: 2px;
}
</style>